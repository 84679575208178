/*
Theme Name: Homely
Theme URI: http://rypecreative.com/
Author: Rype Creative
Author URI: http://rypecreative.com/
Description: Real estate theme
Version: 1.0
License: GNU General Public License v2 or later
License URI: http://www.gnu.org/licenses/gpl-2.0.html
*/

/*====================================================================================*/
 /* TABLE OF CONTENTS
/*====================================================================================*/
/* 01 RESET
/* 02 GLOBAL SETTINGS
/* 03 HEADER
/* 04 SUBHEADER
/* 05 FILTER
/* 06 PROPERTIES
/* 07 AGENTS
/* 08 BLOG
/* 09 MEMBERS
/* 10 MISC MODULES
/* 11 FOOTER

/*====================================================================================*/
 /* 01 RESET
/*====================================================================================*/

* { margin:0px; padding:0px; }

h1,h2,h3,h4,h5 { margin-top:0px; }

ul, ul li { list-style:none; }


/*====================================================================================*/
 /* 02 GLOBAL SETTINGS
/*====================================================================================*/

html { width:100%; height:100%; }

body {
	font-family:'Raleway', Arial;
	font-weight:300;
	font-size:15px;
	color:#464646;
	width:100%;
	height:100%;
	background:#ebf1f5;
}

.container { position:relative; }

.module { position:relative; padding:70px 0px 70px 0px; }
.module.no-padding { padding:0; }
.module.no-padding-top { padding-top:0; }
.module.no-padding-bottom { padding-bottom:0; }

.module-header { margin-bottom:50px; text-align:center; }
.module-header h2 { font-weight:300; font-size:30px; margin-bottom:15px; }
.module-header img { margin-bottom:15px; }
.module-header p { font-size:16px; margin:0 auto; width:60%; }

/* page & post content */
.content p { line-height:1.8; margin-bottom:27px; }
.content ul li { list-style-type:disc; margin-left:20px; }
.content ul li ul{ margin-left:20px; }
.content ul li ul li { list-style-type:circle; }
.content ol { margin-left:15px; }
.content .quote {
	padding:5%;
	border-left:2px solid darken($brand-primary, 5%);
	background:#e7eef4;
	font-size:17px;
	width:85%;
	margin:0 auto;
	margin-bottom:30px;
}

.left { float:left; }
.right { float:right; }
.center { text-align:center; margin:0 auto; }
.clear { clear:both; }
.show-none { display:none; }
.divider { width:100%; height:1px; background:rgba(0, 0, 0, 0.1); margin-bottom:20px; margin-top:20px; }
.divider-fade {
	width:100%;
	height:70px;
	position:absolute;
	border-radius:25px 4px 4px 4px;
	opacity:0.1;
	background: -moz-linear-gradient(top, rgba(0,0,0,0.28) 0%, rgba(0,0,0,0) 72%, rgba(0,0,0,0) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(0,0,0,0.28) 0%,rgba(0,0,0,0) 72%,rgba(0,0,0,0) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(0,0,0,0.28) 0%,rgba(0,0,0,0) 72%,rgba(0,0,0,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#47000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

/* typography */
h1,h2,h3,h4,h5,h6 { font-family:'Raleway', Helvetica; color:#3a464e; }
h1 { font-size:45px; }
h2 { font-size:40px; }
h3 { font-size:30px; }
h4 { font-size:25px; }
h5 { font-size:20px; }

a {
	color:$brand-primary;
	outline:0 !important;
	-webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;
}

a:hover { color:lighten($brand-primary, 7.5%); text-decoration:none; }

.shadow-hover { transition: all 0.4s linear; }
.shadow-hover:hover { box-shadow:0px 10px 25px rgba(0, 0, 0, 0.15); }

.icon { margin-right:4px; }

/* BUTTONS */
.button, input[type="submit"] {
	font-family:'Raleway', Helvetica, Arial;
	font-size:15px;
	font-weight:600;
	padding:10px 35px 10px 35px;
	color:white;
	border-radius:3px;
	border:none;
	background-color:$brand-primary;
	display:inline-block;
	width:auto;
	cursor:pointer;
	transition: all 0.2s linear;
}

.button:hover, input[type="submit"]:hover, .button.grey:hover {
	color:white;
	text-decoration:none;
	background-color:darken($brand-primary, 5%);
}

.button.alt { background-color:#4fba6f; }
.button.alt:hover { background-color:#5fc97e; }

.button.small {
	padding:6px 23px 6px 23px;
	font-size:12px;
}

.button.large {
	padding:12px 45px 12px 45px;
	font-size:17px;
}

.button.grey { color:#8da9b9; cursor:auto; background-color:#cbdfea; }

.button-icon { position:relative; padding-left:56px; padding-right:25px; }
.button-icon.small { padding-left:38px; padding-right:15px; }
.button-icon.large { padding-left:70px; padding-right:35px; }
.button-icon .fa {
	background:rgba(0, 0, 0, 0.1);
	position:absolute;
	left:0;
	top:0;
	border-radius:2px;
	height:100%;
	width:38px;
	text-align:center;
	padding-top:13px;
	font-size:13px;
}
.button-icon.small .fa { padding-top:8px; width:27px; }
.button-icon.large .fa { padding-top:15px; font-size:16px; width:45px; }

/** PAGINATION **/
.pagination { width:100%; }
.pagination ul li { list-style:none; padding-left:4px; padding-right:4px; display:inline-block; }
.pagination ul li.current a { background:darken($brand-primary, 5%); color:white; }
.pagination .button { padding:10px 15px; }

/** FORM ELEMENTS **/
input, textarea, select {
	width:100%;
	padding:9px;
	margin-bottom:20px;
	border-radius:3px;
	border:none;
	font-weight:300;
	font-family:'Raleway' !important;
	background:white;
}

input[type="file"] {
	background:white;
	border:none;
	padding:5px 0px 5px 0px;
	width:auto;
	display:inline-block;
}

.chosen-container { margin-bottom:20px; width:100% !important; }
.chosen-container.chosen-with-drop .chosen-single { border:none; background:white; }
.chosen-container .chosen-single {
	border:none;
	box-shadow:none;
	border-radius:3px;
	background:white;
	height:39px;
	line-height:39px;
	font-size:15px;
}
.chosen-container .chosen-single div { top:8px; right:6px; }
.chosen-container .chosen-drop { border:none; }
.chosen-container .chosen-search { padding:10px; }
.chosen-container .chosen-results { padding:0px 10px 10px 10px; }
.chosen-container .chosen-results li.highlighted { background:$brand-primary; border-radius:2px; }

input[type="checkbox"], input[type="radio"] { width:auto; margin-right:5px; }

textarea { height:130px; }

input.border,
select.border,
textarea.border,
.form-block.border .chosen-container .chosen-single,
.form-block.border .chosen-container .chosen-drop { border:1px solid rgba(0, 0, 0, 0.15); }
.form-block.border .chosen-container .chosen-results { padding-top:10px; }

.form-block label { display:block; }
.form-block label .error { color:red; font-size:12px; margin-left:10px; }

.search-form { position:relative; }
.search-form button[type="submit"] {
	border:none;
	background:none;
	position:absolute;
	right:13px;
	top:10px;
	color:#97aab7;
}

/* SOCIAL ICONS */
.social-icons { margin-bottom:0; }
.social-icons li { display:inline-block; padding-left:5px; padding-right:5px; line-height:1.4; }
.social-icons li:last-child { padding-right:0px; }
.social-icons.circle li a {
	font-size:14px;
	border:1px solid $brand-primary;
	width:28px;
	height:28px;
	display:inline-block;
	text-align:center;
	padding-top:4px;
	border-radius:100%;
}
.social-icons.circle li a:hover { background:$brand-primary; border-color:$brand-primary; color:white; }

/** ALERT BOXES **/
.alert-box {
	background:#f5fad7;
	border:1px solid #b3b4a0;
	border-left:3px solid #b3b4a0;
	padding:10px;
	margin-bottom:20px;
}
.alert-box.success { background:#dbefd4; border-color:#acc7a1; }
.alert-box.error { background:#fad7d7; border-color:#c08585; }
.alert-box.info { background:#d2daef; border-color:#8a97b9; }
.alert-box h4 { font-size:15px; margin:0;}

/** SLICK SLIDER **/
.slider-wrap { position:relative; }

.slider { opacity:0; overflow:hidden; }
.slider.slick-initialized { opacity:1; transition:all 0.4s; }
.slider .slide:not(:first-of-type) { display: none; }
.slider .slide.initialized { display:block; }

.slider-nav { position:absolute; width:100%; top:50%; margin-top:-30px; }
.slider-prev, .slider-next {
	background:white;
	font-size:28px;
	color:#93a1ae;
	width:45px;
	height:45px;
	text-align:center;
	display:block;
	padding-top:2px;
	border:1px solid rgba(0, 0, 0, 0.1);
	border-radius:50px;
	cursor:pointer;
	position:relative;
	z-index:100;
	transition:all 0.4s;
}
.slider-prev:hover, .slider-next:hover {
	background:$brand-primary;
	color:white;
	border-color:$brand-primary;
}
.slider-prev { float:left; margin-left:1%; }
.slider-next { float:right; padding-left:5px; margin-right:1%; }

/** TABS **/
.tabs { margin-bottom:20px; display:none; font-family:'Raleway'; }
.tabs.ui-widget.ui-widget-content,
.ui-tabs .ui-tabs-nav {
	background:transparent;
	padding:0;
	border-radius:0;
	border:none;
}
.ui-tabs .ui-tabs-nav { box-shadow:inset 0px -1px rgba(0, 0, 0, 0.15); }
.ui-tabs .ui-tabs-nav li {
	border:none;
	background:transparent;
}
.ui-tabs .ui-tabs-nav .ui-tabs-anchor { padding:18px 15px; color:#9bb3c7; }

.ui-tabs .ui-tabs-panel {
	background:transparent;
	text-align:left;
	padding:30px 0px 0px 5px;
	margin-top:0px;
}

.tabs li.ui-state-active { background:transparent; border-bottom:2px solid $brand-primary; }
.tabs li.ui-state-active .ui-tabs-anchor { color:$brand-primary; font-weight:600; }

.ui-tabs-hide { display:none; }

/** ACCORDION **/
.ui-accordion .ui-accordion-header {
	border:none;
	margin-bottom:25px;
	padding:20px;
	font-size:18px;
	font-weight:600;
	background:white;
}
.ui-accordion .ui-accordion-header-active { margin-bottom:0; background:darken($brand-primary, 5%); }
.ui-accordion .ui-accordion-content-active { margin-bottom:25px; }
.ui-accordion .ui-accordion-content { border:none; padding:30px; font-family:'Raleway'; }
.ui-accordion-header-icon { display:none; }
.accordion-footer { font-style:italic; font-size:12px; }
.accordion-footer a { color:$brand-primary; font-weight:600; margin-left:8px; margin-right:8px; }

.ui-accordion .ui-accordion-header:before {
    content: "\f105";
    font-family: FontAwesome;
    margin-right:10px;
    font-size:20px;
}
.ui-accordion .ui-accordion-header-active:before { content: "\f107"; }

/* COLOR BAR */
.color-bar {
	width:50px;
	height:3px;
	background:$brand-primary;
	margin-bottom:15px;
	transition:all 0.3s;
}

/*====================================================================================*/
 /* 03 HEADER
/*====================================================================================*/
header { background:white; width:100%; position:relative; z-index:1000; }

/* TOPBAR */
.top-bar {
	background:#e8ebf3;
	color:#838893;
	font-size:12px;
}

.top-bar a { color:#838893; }
.top-bar a:hover { color:$brand-primary; }

.top-bar-item {
	padding:8px;
	display:inline-block;
}
.top-bar-left .top-bar-item:first-child { padding-left:0px; }
.top-bar-right .top-bar-item:first-child { padding-right:0px; }

/* LOGO */
.navbar-header {
	display:inline-block;
	max-width:30%;
	min-width:200px;
	position:relative;
	z-index:1000;
}
.navbar-brand {
	display:inline-block;
	height:auto;
	min-height:83px;
	padding-left:0px;
}
.navbar-brand img { max-width:100%; }

/* MENU TOGGLE */
.navbar-toggle {
	background:#e8ebf3;
	margin:0;
	margin-top:20px;
	position:relative;
	z-index:2;
}
.navbar-toggle .icon-bar { background:#838893; }

/* MAIN MENU */
 .navbar-collapse.collapse { position:relative; z-index:1000; }

 .main-menu-wrap {
 	position:absolute;
	bottom:0;
	right:0px;
 }

.nav.navbar-nav li { position:relative; }
.nav.navbar-nav li a {
	color:#838893;
	padding:29px 20px;
}
.nav.navbar-nav li a:hover { background:transparent; color:$brand-primary; }
.nav.navbar-nav li.current-menu-item > a { box-shadow:inset 0px -2px $brand-primary; }
.nav.navbar-nav li.menu-item-has-children:after {
	content: "\f107";
    font-family: FontAwesome;
    color:rgba(0, 0, 0, 0.5);
	margin-top:29px;
	top:0;
	right: 5px;
	position: absolute;
}
.nav.navbar-nav li:hover.menu-item-has-children:after { color:$brand-primary !important; }
.nav.navbar-nav li:last-child a { padding-right:0; }

/* SUB MENU */
.nav.navbar-nav li:hover .sub-menu { display:block; margin-top:0; }
.sub-menu {
	display:none;
	position:absolute;
	background:#2a2e3b;
	width:200px;
	margin-top:15px;
	font-size:14px;
	-webkit-transition:all 0.4s;
	transition:all 0.4s;
}
.nav.navbar-nav li .sub-menu li a {
	display:block;
	padding:10px;
}
.nav.navbar-nav li .sub-menu li a:hover { color:white !important; }
.nav.navbar-nav li .sub-menu li.menu-item-has-children:after {
	content: "\f105";
	color:rgba(255, 255, 255, 0.2);
	right:10px;
	margin-top:10px;
}

.nav.navbar-nav li .sub-menu li .sub-menu {
	display:none;
	margin-left:200px;
	margin-top:-40px;
	background:#21252f;
}
.nav.navbar-nav li .sub-menu li:hover .sub-menu { display:block; }

/* MEMBER ACTIONS */
.member-actions { padding-top:25px; margin-left:20px; }

/** HEADER DEFAULT **/
.header-default { padding-bottom:30px; }
.header-default .navbar-header { float:none; width:100%; max-width:100%; display:block; }
.header-default .header-details { position:absolute; bottom:0; right:0; }
.header-default .header-item { padding:15px; margin:11px 0px; line-height:1.2; font-size:13px; }
.header-default .header-item:nth-last-child(2) { padding-right:0px; border-left:1px solid rgba(0, 0, 0, 0.1); }
.header-default .header-item table { margin:0 auto; width:100%; }
.header-default .header-search { width:300px; }
.header-default .header-search input { border:1px solid rgba(0, 0, 0, 0.13); margin-bottom:0; }
.header-default .header-search td > .fa { display:none; }
.header-default .header-item td > .fa { color:darken($brand-primary, 5%); font-size:36px; margin-right:10px; }
.header-default .header-item span { font-family:'Open Sans'; font-weight:700; font-size:18px; }
.header-default .navbar-brand { padding:20px 15px; padding-left:0px; }
.header-default .main-menu-wrap { height:60px; top:0px; width:100%; background:#323746; }
.header-default .navbar-toggle.fixed { position:fixed; top:0; left:0; margin-top:0; width:100%; background:#323746; border-radius:0px; padding:15px;}
.header-default .main-menu-wrap.fixed { position:fixed; }
.header-default .main-menu-wrap .container-fixed { max-width:1140px; margin:0 auto; }
.header-default .nav.navbar-nav { float:left; }
.header-default .nav.navbar-nav li a { letter-spacing:0.4px; color:white; font-size:14px; font-weight:600; padding:20px 20px; }
.header-default .nav.navbar-nav li a:hover { color:$brand-primary; }
.header-default .nav.navbar-nav li.menu-item-has-children:after {
	content: "\f107";
    font-family: FontAwesome;
    color:rgba(255, 255, 255, 0.4);
	margin-top:21px;
}
.header-default .nav.navbar-nav li .sub-menu li a { color:rgba(255, 255, 255, 0.5); font-weight:300; }

.header-default .member-actions { padding:0; }
.header-default .member-actions .button { padding:20px 20px 20px 40px; letter-spacing:0.4px; font-size:14px; border-radius:0; }
.header-default .member-actions .button-icon .fa {
	height:auto;
	width:28px;
	height:28px;
	padding-top:9px;
	border-radius:6px;
	top:16px;
	margin-left:-14px;
	background:#4fba6f;
	box-shadow:0px 0px 0px 5px #323746;
}

/*====================================================================================*/
 /* 04 SUBHEADER
/*====================================================================================*/
.subheader {
	background:#787c8a url(/assets/base/theme/images/1920x250.png) no-repeat center;
	background-size:cover;
	color:white;
	padding-top:160px;
	padding-bottom:40px;
	letter-spacing:0.5px;
	position:relative;
	z-index:1;
}
.subheader h1 {
	margin-bottom:0px;
	font-weight:700;
	font-size:40px;
	color:white;
	float:left;
}

.subheader .breadcrumb {
	background:transparent;
	font-weight:600;
	font-size:14px;
	margin-bottom:0;
	padding:0;
	padding-top:20px;
}
.subheader .breadcrumb a { color:white; }
.subheader .breadcrumb a.current { font-weight:600; }
.subheader .breadcrumb .fa { margin-left:6px; margin-right:6px; }

/** SIMPLE SEARCH **/
.subheader.simple-search {
	text-align:center;
	padding:200px 0px;
	background-image:url(/assets/base/theme/images/1920x950.png);
}
.subheader.simple-search h1 { float:none; font-size:48px; font-weight:700; }
.subheader.simple-search p {
	font-size:21px;
	margin-top:15px;
	margin-bottom:35px;
}

.subheader.simple-search .simple-search-form {
	width:65%;
	margin:0 auto;
}

.subheader.simple-search .simple-search-form input[type="text"] {
	border:none;
	border-radius:3px 0px 0px 3px;
	font-size:17px;
	padding:12px;
	height:48px;
	width:85%;
	float:left;
	color:black;
}

.subheader.simple-search .simple-search-form input[type="submit"] {
	border-radius:0px 3px 3px 0px;
	padding:13.5px;
	width:15%;
	float:left;
}

.subheader.simple-search .ui-tabs-nav { display:inline-block; }
.subheader.simple-search .ui-tabs-nav li {
	border-radius:0px;
	margin:0;
	border:none;
	border:1px solid rgba(255, 255, 255, 0.2);
	border-right:none;
	background:rgba(255, 255, 255, 0.1);
	width:120px;
	text-align:center;
}
.subheader.simple-search .ui-tabs-nav li:hover { background:rgba(255, 255, 255, 0.2);; }
.subheader.simple-search .ui-tabs-nav li:first-child { border-radius:3px 0px 0px 3px; }
.subheader.simple-search .ui-tabs-nav li:last-child { border-radius:0px 3px 3px 0px; border-right:1px solid rgba(255, 255, 255, 0.2);  }
.subheader.simple-search .ui-tabs-nav li a { color:white; padding: 15px 12px; display:block; font-size:13px; width:100%; font-weight:700; }
.subheader.simple-search .ui-tabs-nav li.ui-state-active { background:darken($brand-primary, 5%); padding:0; border-color:darken($brand-primary, 5%); }
.subheader.simple-search .ui-tabs-nav li.ui-state-active:after {
  content:"";
  position:absolute;
  bottom:-8px; /* value = - border-top-width - border-bottom-width */
  left:50%; /* controls horizontal position */
  margin-left:-10px;
  border-width:10px 10px 0; /* vary these values to change the angle of the vertex */
  border-style:solid;
  border-color:darken($brand-primary, 5%) transparent;
  display:block;
  width:0;
  z-index:1;
}
.subheader.simple-search .ui-tabs .ui-tabs-panel {
	background:transparent;
	padding:30px 0px 0px 0px;
}

/** SUBHEADER SLIDER SIMPLE **/
.subheader.subheader-slider { background:#e1e5f1; padding:0px; }
.subheader.subheader-slider h1 { float:none; font-size:55px; display:block; margin-bottom:15px; }
.subheader.subheader-slider p { font-size:24px; }
.subheader.subheader-slider p .fa { font-size:19px; margin-right:10px; }
.subheader.subheader-slider .slide {
	background:url(/assets/base/theme/images/1920x950.png) no-repeat center;
	background-size:cover;
	padding:200px 0px 210px 0px;
	text-align:center;
}
.subheader.subheader-slider .slide:nth-child(2) { background:url(/assets/base/theme/images/1920x950.png) no-repeat center; background-size:cover; }
.subheader.subheader-slider .slide .container { position:relative; z-index:10; }
.subheader.subheader-slider .slider-simple-buttons { margin-top:25px; }
.subheader.subheader-slider .slider-simple-buttons .button:last-child {
	margin-left:10px;
	background:transparent;
	box-shadow:inset 0px 0px 0px 2px white;
}
.subheader.subheader-slider .slider-simple-buttons .button:last-child:hover {
	background:darken($brand-primary, 5%);
	color:white;
	box-shadow:inset 0px 0px 0px 2px darken($brand-primary, 5%);
}

.subheader.subheader-slider .slider-next,
.subheader.subheader-slider .slider-prev {
	background:rgba(255, 255, 255, 0.3);
	border:2px solid rgba(255, 255, 255, 0.4);
	color:white;
}
.subheader.subheader-slider .slider-next:hover,
.subheader.subheader-slider .slider-prev:hover { background:$brand-primary; border-color:$brand-primary; color:white; }

/** SUBHEADER SLIDER ADVANCED **/
.subheader.subheader-slider .slider-advanced .slide { text-align:left; }
.subheader.subheader-slider .slider-advanced .slide .img-overlay { background:rgba(0, 0, 0, 0.1); }
.subheader.subheader-slider .slider-advanced .slide-price {
	background:$brand-primary;
	color:white;
	width:185px;
	text-align:center;
	padding:12px 20px;
	font-size:22px;
	font-weight:700;
}
.subheader.subheader-slider .slider-advanced .slide-price span { font-size:13px; font-weight:300; display:block; }
.subheader.subheader-slider .slider-advanced .slide-content {
	padding:30px;
	display:inline-block;
	width:45%;
	color:#2b2f3a;
	background:rgba(255, 255, 255, 0.75);
}
.subheader.subheader-slider .slider-advanced .slide h1 { font-size:32px; color:#2b2f3a; }
.subheader.subheader-slider .slider-advanced .slide p { font-size:17px; font-weight:600; }
.subheader.subheader-slider .slider-advanced .slide .fa { color:$brand-primary; }
.subheader.subheader-slider .slider-advanced .slide p.slide-text { font-weight:300; font-size:15px; }
.subheader.subheader-slider .slider-advanced .slide table { font-size:13px; margin-bottom:25px; margin-top:20px; font-weight:600; width:100%; text-align:center; }
.subheader.subheader-slider .slider-advanced .slide table td { border-right:1px solid rgba(0, 0, 0, 0.07); width:25%; }
.subheader.subheader-slider .slider-advanced .slide table td:last-child { border:none; }
.subheader.subheader-slider .slider-advanced .slide table .fa { margin-bottom:7px; font-size:30px; }
.subheader.subheader-slider .slider-advanced .slide .button-icon .fa { color:white; }
.subheader.subheader-slider .slider-advanced .slide .button.small { margin-top:15px; padding:3px 10px; font-size:10px; }
.subheader.subheader-slider .slider-advanced .slide .button.small.grey { color:white; margin-right:10px; background:#bcc3d8; }

/** SUBHEADER GOOGLE MAPS **/
.subheader.google-maps { background:#e1e5f1; padding:0; height:715px; position:static; }
.subheader.google-maps .container { position:static;}
#map-canvas { width:100%; height:715px; position:absolute; z-index:0; }
.info-box-img { width:180px; margin-right:20px; float:left;  }
.info-box p { color:black; }

.subheader.google-maps.horizontal, .subheader.google-maps.horizontal #map-canvas { height:600px; }

/** SUBHEADER LISTING **/
.subheader-listing-sidebar { padding-bottom:50px; }

/*====================================================================================*/
/* 05 FILTER
/*====================================================================================*/
.filter { min-height:300px; background:#cbd8e3; padding-bottom:40px; }

.filter-header { background:$brand-primary; }
.filter .ui-tabs .ui-tabs-panel { padding:50px 0px 0px 0px; background:transparent; }
.filter .ui-tabs .ui-tabs-nav { box-shadow:none; }
.filter .ui-tabs .ui-tabs-nav .ui-tabs-anchor { text-align:center; font-weight:700; color:white; padding:16px; min-width:100px; }
.filter .ui-tabs .ui-tabs-nav li.ui-state-active a { background:#408dc1; }
.filter .ui-tabs .ui-tabs-nav li.ui-state-active a:after {
  content:"";
  position:absolute;
  bottom:-9px; /* value = - border-top-width - border-bottom-width */
  left:50%; /* controls horizontal position */
  margin-left:-9px;
  border-width:9px 9px 0; /* vary these values to change the angle of the vertex */
  border-style:solid;
  border-color:#408dc1 transparent;
  display:block;
  width:0;
  z-index:1;
}
.filter .ui-tabs .ui-tabs-nav li { margin:0; padding:0; border:none; border-radius:0px; background:none; }

.filter label {  display:block; }
.filter label.label-submit { visibility:hidden; display:inline-block; }
.filter-item {
	float:left;
	margin-right:2.6%;
	width:23%;
}

.filter-item-7 { width:31.6%; }
.filter-item-7:nth-child(3) { margin-right:0; }
.filter-item-7:nth-last-child(4) { width:23%; }
.filter-item-7:nth-last-child(3) { width:23%; }
.filter-item-7:nth-last-child(2) { width:23%; }
.filter-item-7:last-child { width:23%; margin-right:0; }

.noUi-horizontal { height:8px; border:none; box-shadow:none; background:rgba(0, 0, 0, 0.09); margin-bottom:53px; margin-top:7px; }
.filter .noUi-horizontal { background:white; }
.noUi-connect { background:$brand-primary; box-shadow:none; }
.noUi-horizontal .noUi-handle { cursor:pointer; box-shadow:0px 0px 10px rgba(0, 0, 0, 0.1); background:white; width:20px; height:20px; border-radius:100%; }
.noUi-horizontal .noUi-handle:before, .noUi-horizontal .noUi-handle:after { content:''; display:none; }
.noUi-horizontal .noUi-tooltip {
	bottom:auto;
	top:23px;
	font-size:11px;
	background:rgba(0, 0, 0, 0.05);
	border:none;
	padding:3px 4px;
}

.area-filter { width:48.5%; margin-right:3%; float:left;}
.area-filter:nth-last-child(2) { margin-right:0; }

.filter-item .button { width:100%;  }

/** FILTER WITH SLIDER **/
.filter-with-slider { margin-top:-51px; color:white; min-height:auto; position:relative; z-index:100; background:transparent; }
.filter-with-slider .tabs.ui-widget { background:#408dc1; }
.filter-with-slider .ui-tabs .ui-tabs-panel { padding:20px 20px 0px 20px; }
.filter-with-slider .filter-item:nth-last-child(2) { margin-right:0; }
.filter-with-slider label { color:white; }
.filter-with-slider .noUi-horizontal { background:rgba(0, 0, 0, 0.13); }
.filter-with-slider .noUi-connect { background:#323746; }
.filter-with-slider .noUi-horizontal .noUi-tooltip { color:white; }
.filter-with-slider .button { background:#323746; }

/** FILTER GOOGLE MAPS **/
.google-maps .filter {
	padding:20px;
	margin-top:55px;
	width:30%;
	position:relative;
	z-index:100;
	background:$brand-primary;
	float:right;
	color:#464646;
}
.google-maps .filter .filter-toggle { display:none; cursor:pointer; padding:20px; color:white; font-weight:700; }
.google-maps .filter-item { width:100%; float:none; }
.google-maps .filter-item:nth-last-child(2) input { margin-bottom:0px; }
.google-maps .filter-item label { font-size:14px; color:white; }

.google-maps .filter-half { width:48.5%; margin-right:3%; float:left;}
.google-maps .filter-half-last { margin-right:0; }

.google-maps .price-range-num {
	background:rgba(255, 255, 255, 0.1);
	color:rgba(255, 255, 255, 0.8);
}
.google-maps .noUi-connect { background:#323746; }
.google-maps .noUi-horizontal .noUi-tooltip { color:white; }

.google-maps .filter .button { background:#323746; margin-bottom:5px; }
.google-maps .filter .button:hover { background:white; color:$brand-primary; }


/*====================================================================================*/
 /* PROPERTIES
/*====================================================================================*/
/* GLOBAL PROPERTY MODULE */
.property {
	background:white;
	position:relative;
	margin-bottom:30px;
}

.property:hover .property-color-bar { width:100%; }

.property-img { position:relative; display:block; }
.property-img img { max-width:100%; height:auto; }

.property-price {
	color:white;
	position:absolute;
	font-weight:700;
	font-size:23px;
	bottom:10px;
	left:15px;
	z-index:20;
}
.property-price span {
	font-size:12px;
	font-style:italic;
	font-weight:200;
	display:block;
}

.property-tag {
	position:absolute;
	padding:3px 8px;
	font-size:12px;
	z-index:20;
}

.property-tag.featured { right:10px; margin-top:10px; }
.property-tag.status { right:10px; bottom:10px; }

.img-fade {
	width:100%;
	height:80px;
	position:absolute;
	bottom:0;
	background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.65) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.65) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=0 ); /* IE6-9 */
}

.property-color-bar {
	background:$brand-primary;
	height:3px;
	width:0;
	position:absolute;
	z-index:20;
	bottom:0px;
	transition:all 0.4s;
}

.property-content { padding:5%; }
.property-content p { margin:0; }

.property-title {
	border-bottom:1px solid rgba(0, 0, 0, 0.09);
	padding-bottom:20px;
	margin-bottom:20px;
}
.property-title h4 {
	width:100%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.property-title a { color:#3a464e; font-size:18px; }
.property-title a:hover { color:$brand-primary; }

.property-title .fa { color:$brand-primary; }

.property-details {
	color:#9eaab2;
	font-weight:700;
	width:100%;
	font-size:13px;
}

.property-details td { width:33.33%; }

.property-footer {
	background:#d4e0e7;
	padding:3% 5%;
	color:#86929a;
	font-size:13px;
	font-weight:400;
}
.property-footer a { color:#86929a; }
.property-footer a:hover { color:$brand-primary }

/* GLOBAL PROPERTY ROW MODULE */
.property-row { margin-bottom:40px; }
.property-row .property-img { width:35%; display:inline-block; min-height:285px; background:#d4e0e7; float:left; z-index:100; }
.property-row .property-title h4 {
	width: 100%;
 	white-space: nowrap;
 	overflow: hidden;
 	text-overflow: ellipsis;
}
.property-row .property-title h4 a { font-size:20px; }
.property-row .property-address { font-size:16px; }
.property-row .property-content { background:white; padding:20px; width:65%; float:left; }
.property-row .property-text { font-size:14px; margin-top:18px; line-height:1.6; }
.property-row .property-details { font-size:14px; width:auto; }
.property-row .property-footer {
	width:100%;
	position:absolute;
	padding:0px 0px 0px 37%;
	bottom:0;
}
.property-row .property-footer .left { padding-top:11px; }
.property-row .property-footer .button { margin-left:15px; color:white; border-radius:0px; }

.property-row-sidebar .property-img { overflow:hidden; min-height:245px; width:45%; }
.property-row-sidebar .property-content { width:55%; }
.property-row-sidebar .property-title { padding-bottom:13px; margin-bottom:13px; }
.property-row-sidebar .property-footer { padding-left:48%; }

/** PROPERTIES LISTINGS **/
.property-listing-header { margin-bottom:30px; }
.property-listing-header select, .property-listing-header .chosen-container { min-width:180px; margin-bottom:0px; }
.property-count { margin-top:10px; }
.property-layout-toggle { margin-right:20px; }
.property-layout-toggle-item {
	color:#9eaab2;
    display: inline-block;
    width: 38px;
    height: 38px;
    text-align: center;
    padding-top: 9px;
}
.property-layout-toggle-item.active { color:$brand-primary; border: 1px solid rgba(0, 0, 0, 0.1); }

.sidebar-properties { margin-top:-90px; }

.property-listing-map { height:100%; }
.property-listing-map-left { float:left; width:50%; position:fixed; z-index:0; top:0; height:100%; }
.property-listing-map-right { float:right; width:50%; padding:3%; position:relative; box-shadow:0px 0px 20px rgba(0, 0, 0, 0.1); }
.property-listing-map-left #map-canvas { height:100%; }

/** PROPERTY HIDDEN CONTENT **/
.property.property-hidden-content .property-content {
	transition:all 0.4s;
	position:absolute;
	bottom:0;
	width:100%;
	height:100%;
	opacity:0;
	background:rgba(72, 160, 220, 0.7);
	z-index:10;
	color:white;
	display:block;
}
.property.property-hidden-content .property-title { border-color:rgba(255, 255, 255, 0.3); }
.property.property-hidden-content .property-title .fa { color:white; }
.property.property-hidden-content .property-content h4 { color:white; font-size:20px; }
.property.property-hidden-content .property-details { color:white; }

.property.property-hidden-content:hover .property-content { opacity:1.0;  }

/** PROPERTIES FEATURED MODULE **/
.properties.featured .property { width:25%; float:left; margin-bottom:0px; }
.properties.featured .slider-nav {
	width:120px;
	left:50%;
	margin-left:-60px;
	top:auto;
}
.properties.featured .slider-featured { margin-top:55px; }

/** PROPERTY CATEGORIES MODULE **/
.property-cat {
	width:100%;
	height:260px;
	display:block;
	color:white;
	padding:5%;
	margin-bottom:30px;
	background-size:cover;
	background-position:center;
	background-repeat:no-repeat;
	transition:all 0.4s;
}
.property-cat h3 { color:white; font-weight:200; }
.property-cat:hover .color-bar { width:130px; }

.property-cat-apartments { background-image:url(/assets/base/theme/images/1000x560.png);}
.property-cat-houses { background-image:url(/assets/base/theme/images/1000x560.png);}
.property-cat-condos { background-image:url(/assets/base/theme/images/1000x560.png);}
.property-cat-waterfront { background-image:url(/assets/base/theme/images/1000x560.png);}
.property-cat-cozy { background-image:url(/assets/base/theme/images/1000x560.png);}

/** PROPERTY SINGLE **/
.property-single-item { margin-bottom:45px; background:white; padding:30px; }
.property-single-item h4 { position:relative;}
.property-single-item .divider-fade { margin-top:-20px; }
.property-single-item .divider-hex {
	box-shadow: 0px 0px 15px 12px white;
	background:white;
	position:relative;
	z-index:100;
	margin-bottom:10px;
}

.property-single-item.property-main { padding:0; }
.subheader-slider.property-single-item { margin-bottom:0px; }
.property-single-item .property-header { padding:30px; }
.property-single-item .property-header h4 { display:inline-block; width:auto; white-space:normal; }
.property-single-item .property-header.property-header-slider {
	position:absolute;
	padding:0;
	z-index:10;
	left:15px;
	right:15px;
	bottom:30%;
}
.property-single-item .property-header.property-header-slider .property-title { border-bottom:1px solid rgba(255, 255, 255, 0.2); padding-bottom:0px; }
.property-single-item .property-header.property-header-slider h4 { color:white; display:block; font-weight:700; font-size:32px; }
.property-single-item .property-header.property-header-slider p { display:inline-block; font-size:20px; }
.property-single-item .property-header.property-header-slider .property-price-single { color:white; }
.property-single-item .property-header.property-header-slider a { color:white; }

.property-price-single {
	font-size:30px;
	font-weight:700;
	color:$brand-primary;
	text-align:right;
	line-height:1.4;
}
.property-price-single span {
	display:block;
	font-size:14px;
}

.property-single-tags .property-tag {
	position:relative;
	right:0;
	bottom:0;
}
.property-single-tags .property-tag.featured { margin-top:0; }

.property-single-item .property-details-single { width:100%; position:relative; z-index:10; box-shadow:0px 10px 20px rgba(0, 0, 0, 0.1); line-height:1.1; background:$brand-primary; color:white; font-size:13px; font-weight:300; }
.property-single-item .property-details-single td { border-right:1px solid rgba(255, 255, 255, 0.1); width:25%; text-align:center; padding:20px 0px; }
.property-single-item .property-details-single td .fa { font-size:28px; display:block; }
.property-single-item .property-details-single td span { font-size:24px; font-weight:600;}
.property-single-item.property-details { padding:0; }

.property-gallery { position:relative; }
.property-gallery img { max-width:100%; height:auto; }
.property-gallery .slider-nav { top:41%; }
.property-gallery .slide-counter {
	width:60px;
	position:absolute;
	z-index:10;
	color:white;
	left:10px;
	top:10px;
	border-radius:3px;
	background:rgba(0, 0, 0, 0.25);
	text-align:center;
	padding:5px;
	font-size:12px;
	font-family:'Open Sans';
}
.property-gallery .property-gallery-pager { padding:20px 7px 20px 20px; margin-bottom:0px; }
.property-gallery .property-gallery-pager a { margin-bottom:-3px; opacity:0.6; cursor:pointer; max-height: 135px; display:inline-block; margin-right:1.5%; border-bottom:3px solid rgba(0, 0, 0, 0); }
.property-gallery .property-gallery-pager a img { position:relative; z-index:-1; }
.property-gallery .property-gallery-pager a.slick-current { opacity:1; box-shadow:inset 0px -3px $brand-primary; }
.property-gallery .property-gallery-pager a:last-child { margin-right:0; }

.property-gallery.full-width .property-gallery-pager { padding-right:0px; padding-left:0px; }
.property-gallery.full-width .slide-counter { top:auto; bottom:0; left:50%; margin-left:-30px; }
.property-gallery.full-width .slide { position:relative; padding:270px 0px; }
.property-gallery.full-width .slide .img-fade { height:180px; }
.property-gallery.full-width .slider-nav { top:37%; }

.property-gallery.full-width #slide1 { background:url(/assets/base/theme/images/1837x1206.png) center / cover no-repeat; }
.property-gallery.full-width #slide2 { background:url(/assets/base/theme/images/1837x1206.png) center / cover no-repeat; }
.property-gallery.full-width #slide3 { background:url(/assets/base/theme/images/1837x1206.png) center / cover no-repeat; }
.property-gallery.full-width #slide4 { background:url(/assets/base/theme/images/1837x1206.png) center / cover no-repeat; }
.property-gallery.full-width #slide5 { background:url(/assets/base/theme/images/1837x1206.png) center / cover no-repeat; }
.property-gallery.full-width #slide6 { background:url(/assets/base/theme/images/1837x1206.png) center / cover no-repeat; }
.property-gallery.full-width #slide7 { background:url(/assets/base/theme/images/1837x1206.png) center / cover no-repeat; }

.additional-details-list {
	columns:2;
	-webkit-columns:2;
	-moz-columns:2;
}
.additional-details-list li {
	list-style:none !important;
	margin-left:0 !important;
	border-bottom:1px solid rgba(0, 0, 0, 0.1);
	line-height:2.3;
	padding-right:10px;
}
.additional-details-list li span { float:right; font-weight:700; }

.amenities-list {
	columns:4;
	-webkit-columns:4;
	-moz-columns:4;
}
.amenities-list li { font-weight:600; color:#556878; display:block; line-height:2.4; }
.amenities-list li .fa {
	background:$brand-primary;
	color:white;
	width:22px;
	height:22px;
	font-size:14px;
	text-align:center;
	border-radius:2px;
	padding-top:4px;
}
.amenities-list li .fa.fa-close { background:#9bb3c7; }

#map-single { width:100%; height:300px; }

.property-single-item .agent { margin-bottom:0px; }
.property-single-item .agent-img { width:25%; float:left; }
.property-single-item .agent .button.grey { margin-right:10px; }
.property-single-item .agent-content { width:75%; padding-top:0; float:left; }
.property-single-item .agent-content .button { position:relative; z-index:100; }

.widget.property-single-item h4 { margin-bottom:10px; }
.property-related .property { margin-bottom:0px; }
.property-related .property .property-content { border-right:1px solid rgba(0, 0, 0, 0.06); border-left:1px solid rgba(0, 0, 0, 0.06); }

.sidebar.sidebar-property-single { padding-left:15px; }

/** SUBMIT PROPERTY **/
.subheader-property-submit { padding-bottom:80px; }

.form-nav { margin-bottom:40px; display:inline-block; width:100%; }
.form-nav-item { position:relative; float:left; width:20%; text-align:center; font-size:13px; color:#9bb0bb; font-weight:700; }
.form-nav-item span {
	display:inline-block;
	background:white;
	color:#3a464e;
	width:35px;
	height:35px;
	text-align:center;
	border-radius:100%;
	font-size:15px;
	padding-top:7px;
	margin-bottom:7px;
	box-shadow:0px 0px 20px rgba(0, 0, 0, 0.07);
	position:relative;
	z-index:10;
}

.form-nav-item:after {
	content:'';
	width:120px;
	height:4px;
	display:block;
	background:white;
	position:absolute;
	right:-60px;
	top:17px;
}

.form-nav-item:nth-last-child(2):after { content:''; display:none; }

.form-nav-item.completed { color:#3a464e; }
.form-nav-item.completed span { background:#4fba6f; color:white; }

.form-next, .multi-page-form-content button[type="submit"] { margin-left:10px; }
.multi-page-form-content button[type="submit"] { margin-top:20px; }

.multi-page-form-content {
	background:white;
	margin-bottom:30px;
	padding:30px;
	display:none;
}
.multi-page-form-content.active { display:block; }

.multi-page-form-content h4 {
	color:$brand-primary;
	font-weight:700;
	font-size:25px;
	margin-bottom:3px;
	margin-top:3px;
}

.multi-page-form-content .error { color:red; margin-left:7px; font-size:13px; }

.property-submit-title { margin-bottom:30px; }
.property-submit-title p { color:#8594a9; }

.property-submit-num {
	display:block;
	color:white;
	font-size:25px;
	font-weight:700;
	background:$brand-primary;
	width:45px;
	height:45px;
	text-align:center;
	padding-top:4px;
	border-radius:100%;
	margin-right:10px;
}

.additional-img-container table { width:100%; border-bottom:1px solid #e7e7e7; margin-top:15px; margin-bottom:15px; }
.add-additional-img { margin-bottom:15px; }
.delete-additional-img { cursor:pointer; margin-top:10px; font-size:13px; }

.multi-page-form-content #map-single { margin-bottom:30px; }
.multi-page-form-content .map-input { margin-top:10px; margin-left:10px; width:70%; }

.amenities-list { margin-bottom:30px; }
.amenities-list label { margin-bottom:15px; }


/*====================================================================================*/
 /* AGENTS
/*====================================================================================*/
/* GLOBAL AGENT MODULE */
.agents-featured {
	border-top:1px solid #d6e1e8;
	background:#eff5f9 url(/assets/base/theme/images/agent-bg.png) no-repeat;
	background-position:bottom;
	background-size:cover;
}

.agent {
	background:white;
	position:relative;
	margin-bottom:30px;
}

.agent-tag {
	position:absolute;
	padding:3px 8px;
	font-size:12px;
	z-index:20;
	bottom:10px;
	left:10px;
}

.agent-img { position:relative; display:block; }
.agent-img img { max-width:100%; height:auto; }

.agent-content { padding:5%; }
.agent-content p { margin:0; }

.agent-details {
	border-bottom:1px solid rgba(0, 0, 0, 0.09);
	padding:0px 10px 15px 10px;
	margin-bottom:15px;
	font-size:14px;
	line-height:1.9;
}
.agent-details a { color:#3a464e; font-size:20px; }
.agent-details a:hover { color:$brand-primary; }

.agent-details .fa { color:$brand-primary; font-size:12px; margin-right:8px; }

.agent-content .social-icons.circle { display:inline-block; }
.agent-content .social-icons.circle li { padding-left:4px; padding-right:4px; }
.agent-content .social-icons.circle li a { color:#9bb0bb; border:1px solid #9bb0bb; }
.agent-content .social-icons.circle li a:hover { color:white; border-color:$brand-primary; }

/** AGENT ROW **/
.agent-row { margin-bottom:40px; }
.agent-row .agent-img {
	width:30%;
	display:inline-block;
	overflow:hidden;
	min-height:285px;
	max-height:285px;
	background:#d4e0e7;
	float:left;
	z-index:100;
}

.agent-row .agent-content {
	background:white;
	padding:25px;
	width:70%;
	float:left;
	min-height:285px;
	position:relative;
}

.agent-row .agent-text { font-size:14px; margin-top:18px; line-height:1.6; }

.agent-row .agent-details { padding-left:0px; padding-right:0px; }
.agent-row .agent-details p { display:inline-block; margin-right:15px; }

.agent-row .agent-footer {
	text-align:left;
	left:25px;
	right:25px;
	position:absolute;
	bottom:25px;

}
.agent-row .agent-footer .social-icons { margin-top:5px; }

.agent-row-sidebar .agent-img { min-height:254px; max-height:254px;  }
.agent-row-sidebar .agent-text { font-size:13px; }
.agent-row-sidebar .agent-content { min-height:254px; }
.agent-row-sidebar .agent-footer { bottom:20px; }

/** AGENT SINGLE **/
.agent-single { margin-bottom:50px; }
.agent-single .agent-img { margin:1%; width:30%; float:left; }
.agent-single .agent-img .hex {
	position:absolute;
	right:-37px;
	top:20px;
	-ms-transform: rotate(90deg); /* IE 9 */
    -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
    transform: rotate(90deg);
    height:50px;
}
.agent-single .agent-content { position:relative; z-index:10; padding:30px; width:38%; float:left; }
.agent-single .center { text-align:left; }

.agent-single .agent-details { border-bottom:none; margin-bottom:10px; }
.agent-single .agent-details p {
	border-bottom:1px solid rgba(0, 0, 0, 0.1);
	padding-top:4px;
	padding-bottom:4px;
}
.agent-single .agent-details p span { float:right; font-weight:600; }

.agent-single .agent-form {
	width:30%;
	float:right;
	background:darken($brand-primary, 5%);
	color:white;
	padding:57px 30px;
	margin-top:-20px;
	margin-bottom:-20px;
	box-shadow:-10px 0px 20px rgba(0, 0, 0, 0.1);
}
.agent-single .agent-form h4 { margin-bottom:20px; color:white; }
.agent-single .agent-form textarea { height:100px; }
.agent-single .agent-form input[type="submit"] { background:#323746; margin-bottom:0px; }

.agent-properties .property .property-content { border-right:1px solid rgba(0, 0, 0, 0.06); border-left:1px solid rgba(0, 0, 0, 0.06); }

/*====================================================================================*/
 /* 04 BLOG
/*====================================================================================*/
.blog-post { background:white; margin-bottom:40px;}

.blog-post-img { position:relative; display:block; }
.blog-post-date {
	position:absolute;
	background:url(/assets/base/theme/images/hexagon.png) no-repeat;
	line-height:1.1;
	bottom:-21px;
	padding-top:7px;
	left:30px;
	font-size:12px;
	width:90px;
	text-align:center;
}
.blog-post-date span { font-weight:bold; font-size:22px; display:block; }
.blog-post-img .img-fade { opacity:0.7; }
.blog-post-img img { max-width:100%; height:auto; }

.blog-post-details { margin-bottom:20px; padding:10px 0px 15px 0px; border-bottom:1px solid rgba(0, 0, 0, 0.1);  }
.blog-post-details li { margin-right:13px; font-weight:600; font-size:13px; color:#9eaab2; list-style:none !important; display:inline; }
.blog-post-content { padding:5%; }
.blog-post-content h3 a { font-weight:100; color:#3a464e; }
.blog-post-content h3 a:hover { color:darken($brand-primary, 5%); }

.blog-post-creative { margin-bottom:30px; }
.blog-post-creative h3 { font-size:23px; margin-top:15px; }
.img-overlay {
	position:absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
	background:rgba(89, 174, 233, 0.6);
	text-align:center;
	color:white;
	font-size:50px;
	padding-top:40px;
}
.img-overlay.black { background:rgba(0, 0, 0, 0.25);}
.img-overlay.blacker { background:rgba(0, 0, 0, 0.5);}
.img-overlay.green { background:rgba(79, 186, 111, 0.6); }
.img-overlay.gold { background:rgba(192, 172, 61, 0.6); }
.img-overlay.red { background:rgba(192, 92, 61, 0.6); }
.img-overlay.purple { background:rgba(157, 108, 188, 0.6); }

/** BLOG SINGLE **/
.blog-post-share .social-icons.circle { margin-top:-3px; }
.blog-post-share .social-icons.circle li { padding:0; }
.blog-post-share .social-icons li a {
	border-color:#9eaab2;
	color:#9eaab2;
}

.comment-list, .blog-post-related { margin-bottom:50px; }
.comment-list .comment { margin-bottom:10px; }
.comment-list .button.grey { line-height:1.6; margin:0; display:inline-block; margin-left:7px; padding:1px 10px 3px 10px;}
.comment-list .arrow {
	width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid $brand-primary;
    float: left;
    margin-top: 8px;
    margin-left: -6px;
}
.comment-list .comment-text {
	margin-bottom:20px;
	background:white;
	padding:3%;
	border-left:2px solid $brand-primary;
}
.comment-list .comment-text p { margin-bottom:0px; float:left; display:inline-block; }

.comment-details { margin-top:3px; font-size:12px;  }
.comment-details a { color:#9eaab2; margin-left:14px; }
.comment-details a:hover { color:$brand-primary; }

.comment-form form { margin-top:30px; }
.comment-form h4 { margin-bottom:30px; }
.comment-form h4 span { margin-bottom:7px; display:block; }

/** SIDEBAR WIDGETS **/
.sidebar { padding-left:40px; }
.sidebar-left { padding-left:0px; padding-right:40px; }

.widget-content.box {
	background:white;
	padding:5%;
}

.widget h4 {
	margin-bottom:30px;
	font-size:20px;
	font-weight:600;
}
.widget img { max-width:100%; height:auto; }
.widget h4 span { display:block; margin-bottom:15px; }

.widget ul.bullet-list { margin-left:20px; line-height:1.9; }
.widget ul.bullet-list li { position:relative; list-style:none; font-weight:500; }
.widget ul.bullet-list li:before {
	content: "\f105";
    font-family: FontAwesome;
    color:rgba(0, 0, 0, 0.5);
	top:0;
	left: -20px;
	position: absolute;
}

.widget-sidebar { margin-bottom:30px; padding-bottom:30px; border-bottom:1px solid rgba(0, 0, 0, 0.1) }

.widget.recent-posts p { font-size:13px; }

.widget.recent-properties h5, .widget.recent-posts h5 { font-size:18px; line-height:1.2;}
.widget .recent-property { margin-bottom:20px; }
.widget .recent-property img { margin-bottom:6px; }
.widget .recent-property:last-child { margin-bottom:0px; }

.widget.advanced-search h4 {
	background:$brand-primary;
	position:relative;
	z-index:10;
	color:white;
	padding:4% 5%;
	margin-bottom:0px;
	box-shadow:0px 0px 0px 8px rgba(255, 255, 255, 0.2);
}
.widget.advanced-search h4 span { margin-bottom:5px; }

/*====================================================================================*/
 /* MEMBERS
/*====================================================================================*/
.member-card {
	background:white;
}

.member-card-header {
	color:white;
	background:$brand-primary;
	padding:0px 20px 20px 20px;
	text-align:center;
}
.member-card-avatar {
	display:inline-block;
	border-radius:100%;
	overflow:hidden;
	border:5px solid rgba(255, 255, 255, 0.4);
	width:90px;
	height:90px;
	margin-top:-30px;
	margin-bottom:10px;
	box-shadow:0px 10px 25px rgba(0, 0, 0, 0.16);
	transition:all 0.4s;
 }
.member-card-avatar:hover { border:5px solid rgba(255, 255, 255, 0.8); }
.member-card-header h3 { font-size:20px; color:white; }
.member-card-header p { font-size:13px; }

.member-card-content { text-align:center; position:relative; }
.member-card-content .hex { margin-top:-17px; position:absolute; margin-left:-45px; }
.member-card-content ul { position:relative; z-index:10; }
.member-card-content ul li.active a { border-right:2px solid $brand-primary; color:$brand-primary; font-weight:700; }
.member-card-content ul li a { text-align:left; border-bottom:1px solid rgba(0, 0, 0, 0.1); padding:16px 20px 13px 20px; display:block; color:#3a464e; }
.member-card-content ul li a:hover { background:#deebf2; }
.member-card-content ul li .icon { margin-right:7px; }

/** PROFILE **/
.profile-avatar { max-width:100%; height:auto; margin-bottom:15px; }
.edit-avatar .button { margin-bottom:25px; }

/** MY PROPERTIES **/
.my-properties-list tr { height:140px; border:none; border-bottom:1px solid #cbdfea; }
.my-properties-list tr:first-child { height:50px; }
.my-properties-list th { font-size:13px; }
.my-properties-list .property-img { width:15%; padding-right:20px; display:table-cell; }
.my-properties-list .property-title { width:28%; margin:0; padding:0; border:none; }
.my-properties-list .property-title .property-address { margin-top:3px; color:#9bb3c7; font-size:13px; margin-bottom:15px; }
.my-properties-list .property-post-status { width:15%; }
.my-properties-list .property-post-status .button.small { font-size:11px; padding:5px 10px; }
.my-properties-list .property-date { width:15%; }
.my-properties-list .property-actions { width:27%; }
.my-properties-list .property-actions a { font-size:13px; padding-left:7px; padding-right:7px; }

/** FAVORITE PROPERTIES **/
.favorite-properties-list .property-img { width:20%; padding-right:4%; }
.favorite-properties-list .property-title { width:55%; }
.favorite-properties-list .property-actions { width:25%; }


/*====================================================================================*/
 /* MISC MODULES
/*====================================================================================*/
.about-video-thumb { max-width:100%; height:auto; }

/* SERVICES */
.services { padding-top:0; }
.services .service-item {
	background:white;
	padding:0% 10% 12% 10%;
	text-align:center;
	line-height:1.6;
	font-size:14px;
	position:relative;
	z-index:10;
}

.services .service-item:hover .fa { background:#67b5ea; }

.services .service-item .fa {
	width:110px;
	height:110px;
	color:white;
	background:darken($brand-primary, 5%);
	font-size:45px;
	padding-top:25px;
	border-radius:100px;
	margin-top:-40px;
	margin-bottom:30px;
	border:5px solid white;
	transition:all 0.4s;
}

.services .service-item h4 {
	font-weight:700;
	font-size:18px;
	color:#323746;
	margin-bottom:20px;
}

/** TEAM (LEADERS) **/
.team-member { margin-bottom:30px; }
.team-member img { max-width:100%; height:auto; }
.team-member-img { position:relative; color:white;}
.team-member-img .img-overlay { opacity:0; transition:all 0.4s; }
.team-member-img p { opacity:0; transition:all 0.4s; text-align:center; line-height:1.6; font-size:17px; position:absolute; z-index:10; padding:20px; bottom:0px; }
.team-member-content { padding:20px; line-height:2.1; position:relative; background:white; text-align:center; }
.team-member-content h4, .team-member-content p { margin:0; position:relative; z-index:10; }
.team-member-content .hex {
	margin-top:-20px;
	top:0;
	left:50%;
	margin-left:-43px;
	z-index:0;
	position:absolute;
}
.team-member-content .social-icons { margin-top:10px; }

.team-member:hover .team-member-img .img-overlay { opacity:1.0; }
.team-member:hover .team-member-img p { opacity:1.0; bottom:20px; }

/** CALL TO ACTIONS **/
.cta {
	background:darken($brand-primary, 5%);
	color:white;
	padding:60px 0px;
}
.cta h3, .cta h2 { color:white; font-weight:200; }

.cta.newsletter {
	background:darken($brand-primary, 5%);
	background-image:url(/assets/base/theme/images/send.png);
	background-repeat:no-repeat;
	background-position:20% 50%;
}
.cta.newsletter .newsletter-form { position:relative; }

.cta.newsletter input[type="email"] {
	margin-top:15px;
	background:transparent;
	padding:10px 5px;
	border:none;
	border-radius:0px;
	border-bottom:1px solid white;
	color:white;
	font-size:16px;
	outline:none !important
}

.cta.newsletter input[type="email"]::-webkit-input-placeholder { color: white; }
.cta.newsletter input[type="email"]:-moz-placeholder { color: white; opacity: 1; }
.cta.newsletter input[type="email"]::-moz-placeholder { color: white; opacity:1; }
.cta.newsletter input[type="email"]:-ms-input-placeholder { color:white; }

.cta.newsletter button[type="submit"] {
	background:none;
	border:none;
	position:absolute;
	right:0;
	top:23px;
}

.cta.hexs { background:darken($brand-primary, 5%) url(/assets/base/theme/images/pattern.jpg) no-repeat bottom; background-size:cover; }
.cta.hexs .hex { min-height:195px; padding-top:57px; text-align:center; position:relative; }
.cta.hexs .hex p { position:relative; z-index:10; }
.cta.hexs .hex p span { font-size:33px; font-weight:700; display:block; }
.cta.hexs .hex img { position:absolute; left:50%; margin-left:-100px; top:0; }

.cta.image-bg {
	padding:150px 0px;
	position:relative;
	text-align:center;
	background:darken($brand-primary, 5%) url(/assets/base/theme/images/1920x950.png) no-repeat center;
	background-attachment:fixed;
	background-size:cover;
}

.cta.image-bg p { font-size:20px; margin-top:15px; margin-bottom:30px; }

/** TESTIMONIALS **/
.testimonials {
	background:darken($brand-primary, 5%) url(/assets/base/theme/images/pattern.jpg) no-repeat;
	background-size:cover;
	background-position:bottom;
	color:white;
	text-align:center;
}
.testimonials a { color:white; }
.testimonials h2 { color:white; }
.testimonials h3 {
	width:80%;
	margin:0 auto;
	color:white;
	font-weight:200;
	background:rgba(0, 0, 0, 0.1);
	padding:30px 40px;
	border-radius:4px;
	line-height:1.5;
	position:relative;
	margin-bottom:30px;
}

.testimonials h3:after {
  content:"";
  position:absolute;
  bottom:-15px; /* value = - border-top-width - border-bottom-width */
  left:50%; /* controls horizontal position */
  margin-left:-10px;
  border-width:15px 15px 0; /* vary these values to change the angle of the vertex */
  border-style:solid;
  border-color:rgba(0, 0, 0, 0.1) transparent;
  display:block;
  width:0;
  z-index:1;
}

.testimonial-details {  margin:0 auto; display:inline-block; }
.testimonial-img {
	display:inline-block !important;
	margin-bottom:10px;
	width:67px;
	height:67px;
	border-radius:100%;
	box-shadow:0px 0px 0px 6px rgba(255, 255, 255, 0.2);
}
.testimonial-name { font-size:18px; margin-top:5px; margin-bottom:0px; }

.slider-nav-testimonials .slider-prev,
.slider-nav-testimonials .slider-next { background:transparent; color:white; border:2px solid white; }
.slider-nav-testimonials .slider-prev:hover,
.slider-nav-testimonials .slider-next:hover { background:white; color:darken($brand-primary, 5%); }

/** CONTACT **/
.contact-details {
	background:$brand-primary url(/assets/base/theme/images/contact-bg.png) no-repeat bottom;
	background-size:cover;
}
.contact-item { min-height:220px; background:rgba(0, 0, 0, 0.05); border:1px solid rgba(255, 255, 255, 0.3); padding:30px; color:white; text-align:center; }
.contact-item h4 { color:white; font-size:20px; font-weight:600; }
.contact-item .fa {
	font-size:30px;
	width:58px;
	height:58px;
	text-align:center;
	padding-top:11px;
	border:2px solid white;
	border-radius:100%;
	margin-bottom:20px;
}
.contact-item .social-icons li { padding:0; }
.contact-item .social-icons .fa { border:none; color:white; font-size:17px; width:35px; height:35px; padding-top:10px; margin-bottom:0px; }
.contact-item p { margin-bottom:0px; }

/** LOGIN **/
.login p { text-align:center; }
.login .note { font-size:13px; }
.login-form {
	background:white;
	padding:30px;
	box-shadow:0px 0px 15px rgba(0, 0, 0, 0.05);
	margin-bottom:20px;
}

/** 404 Error **/
.page-not-found {
	text-align:center;
	padding:160px 0px;
}

.page-not-found h2 { font-size:120px; font-weight:700; }
.page-not-found p { margin:20px 0px; }

/** ELEMENTS **/
.elements .row { margin-bottom:60px; }
.elements .button { margin-right:7px; }

/*====================================================================================*/
 /* 04 FOOTER
/*====================================================================================*/
footer {
	background:#323746;
	padding:60px 0px;
	color:#8e95ac;
	font-size:13px;
	position:relative;
	z-index:10;
}

footer h4 { color:white; }

footer .divider { background:rgba(255, 255, 255, 0.1); }

.footer-logo { display:block; margin-bottom:20px; }
.footer-logo img { width:150px; }

.from-the-blog h3 {
	color:white;
	font-size:16px;
}

.bottom-bar {
	background:#262a35;
	color:#8e95ac;
	padding:20px 0px;
	text-align:center;
	font-size:13px;
	position:relative;
	z-index:10;
}
