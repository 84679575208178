.faqs-content {
  @extend .about-content;

  &__right {
    margin-top: $grid-gutter-width*2;
    @media(min-width: $screen-md-min) {
      margin-top: 0;
    }
  }
  .home-services__item {
    h3 {
      margin: 0;
      font-size: 40px;
    }
  }
}
