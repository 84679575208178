.home-slideshow {
  &.subheader-slider .slide {
    @media (min-width: $screen-lg-min) {
      padding: 300px 0px;
    }
  }
}
.home-top {
  position: relative;
  z-index: 1;
  background: white;
  &__text {
    h2 {
      font-weight: bold;
      font-size: 50px;
      color: $brand-primary;
      margin-bottom: $grid-gutter-width*2;
    }
    p {
      font-size: 1.8em;
      font-weight: lighter;
    }
  }
  @media (min-width: $screen-sm-min) {
    > .container {
      margin-top: -200px;
    }
    &__bg {
      position: absolute;
      top: -130px;
      left: 0;
      border-bottom: 300px solid white;
      border-right: 50px solid transparent;
      height: 0;
      width: (100% / 12 * 8);
    }
    &__text {
      padding: 30px 60px 0 0;
      p {
        font-size: 1.2em;
      }
    }
    &__logo img {
      display: block;
      width: 88%;
      height: auto;
      transform: rotate(-10deg);
      transform-origin: top right;
      // transform-origin: center;
      transform-origin: 60% 0%;
      animation-name: pendulum;
      animation-delay: 0s;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      animation-direction: normal;
      // animation-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86); // easeInOutCirc
      animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1); // easeInOutCubic
      // animation-timing-function: cubic-bezier(0.77, 0, 0.175, 1); // easeInOutQuart

      @keyframes pendulum {
        0% {
          transform: rotate(-8deg);
        }
        50% {
          transform: rotate(-12deg);
        }
        100% {
          transform: rotate(-8deg);
        }
      }
      // @include transition(all .8s ease);
      // &:hover {
      //   // transform: rotate(-370deg);
      //   // transform: rotate(-15deg);
      //   transform: scale(1.1);
      //   // width: 60%;
      // }
    }
  }
  @media (min-width: $screen-md-min) {
    > .container {
      margin-top: -250px;
    }
    &__bg {
      top: -160px;
    }
    &__text {
      padding: 60px 100px 0 0;
      h2 {
        font-size: 60px;
      }
    }
  }
  @media (min-width: $screen-lg-min) {
    > .container {
      margin-top: -400px;
    }
    &__bg {
      top: -185px;
      width: 50%;
    }
    &__text {
      padding: 180px 60px 0 0;
      h2 {
        font-size: 70px;
        margin-bottom: $grid-gutter-width*2;
      }
    }
    &__logo img {
      width: 88%;  // 90%
    }
  }
}

.home-services {
  background: white;
  &__second-title {
    display: none;
    @media (min-width: $screen-md-min) {
      display: block;
      position: absolute;
      z-index: 1;
      top: -($font-size-h1 + $grid-gutter-width*2) / 2;
      left: 50%;
      padding: $grid-gutter-width $grid-gutter-width*3;
      line-height: 1;
      border-radius: ($font-size-h1 + $grid-gutter-width*2) / 2;
      background: $brand-primary;
      color: white;
      font-size: $font-size-h1;
      font-weight: bold;
      text-transform: uppercase;
    }
  }
  &__container {
    position: relative;
    padding: 0 $grid-gutter-width/2;
  }
  &__line {
    border-top: solid 1px $brand-primary;
  }
  &__sidebar {
    position: relative;
  }
  h2 {
    margin: 0 0 $grid-gutter-width 0;
    text-transform: uppercase;
    font-size: 50px;
    font-weight: lighter;
    color: $brand-primary;
    @media (min-width: $screen-md-min) {
      position: absolute;
      top: 0;
      right: $grid-gutter-width;
      margin: 0;
      white-space: nowrap;
      // transform: rotate(-90deg);
      // transform-origin: bottom right;
      @include rotate(-90deg);
      @include transform-origin(bottom right);
    }
  }
  &__item {
    margin-top: 0;
    margin-bottom: $padding-base-vertical;
    color: white;
    &__bg {
      position: relative;

      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,000000+54,000000+68,000000+100&0+0,0+32,0.23+54,0.42+68,0.7+100 */
        background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(104,104,104,0) 32%, rgba(0,0,0,0.23) 54%, rgba(0,0,0,0.42) 68%, rgba(0,0,0,0.7) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(104,104,104,0) 32%,rgba(0,0,0,0.23) 54%,rgba(0,0,0,0.42) 68%,rgba(0,0,0,0.7) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(104,104,104,0) 32%,rgba(0,0,0,0.23) 54%,rgba(0,0,0,0.42) 68%,rgba(0,0,0,0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#b3000000',GradientType=0 ); /* IE6-9 */
      }
    }
    &__content {
      position: relative;
      display: block;
      padding: $grid-gutter-width*1.5 $grid-gutter-width;

      color: white;
      text-shadow: 1px 1px 0 $text-color;

      &:hover,
      &:focus {
        color: white;
        text-decoration: none;
      }
    }
    h3 {
      margin: 0 0 $grid-gutter-width 0;
      font-size: 40px;
      font-weight: lighter;
      color: white;
    }
    @media (min-width: $screen-sm-min) {
      font-size: 1.2em;
      h3 {
        font-size: 70px;
      }
      &__description {
        padding-left: $grid-gutter-width*2;
      }
    }
    @media (min-width: $screen-md-min) {
      &__bg {
        margin-left: $grid-gutter-width/2;
        padding-left: 0;
        @include transition(all .2s ease);
        &:hover {
          margin-left: 0;
          padding-left: $grid-gutter-width/2;
        }
      }
    }
  }
}
