@import "variables";
@import "../../../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";

@import "../../../../static/base/css/common";

body {
  // overflow-y: scroll;
  font-weight: 400;
}


.header-default .member-actions .button {
  @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border);
}
.header-default .member-actions .button-icon .fa {
  background: $btn-primary-bg;
}
#footer .contacts-btn {
  @include button-variant(#323746, $btn-primary-bg, $btn-primary-border);
}


@import "home";
@import "about";
@import "faqs";
@import "contacts";
