.contact-item--email {
  padding-left: $padding-xs-horizontal;
  padding-right: $padding-xs-horizontal;
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    p {
      font-size: 80%;
    }
  }
}
.contact-item--map {
  a {
    &,
    &:hover,
    &:focus {
      color: white;
      text-decoration: none;
    }
  }
}
